import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AuthStoreActions,
  AuthStoreMutations,
} from "@/store/enums/AuthStoreEnum";
import firebase from "firebase";
import { passwordMask } from "@/core/helpers/utilities";
import TokenModel from "@/store/models/TokenModel";

@Module
class AuthModule extends VuexModule {
  phone: string | null = null;
  confirmResult: firebase.auth.ConfirmationResult | null = null;
  enterDuration = 180;

  get getPhone(): string | null {
    return this.phone;
  }

  get getMaskedPhone(): string | null {
    if (this.phone !== null) return passwordMask(this.phone!);
    else return this.phone;
  }

  get getEnterDuration(): number {
    return this.enterDuration;
  }

  @Mutation
  [AuthStoreMutations.SET_PHONE](phone) {
    this.phone = phone;
  }

  @Mutation
  [AuthStoreMutations.SET_CONFIRM_RESULT](confirmResult) {
    this.confirmResult = confirmResult;
  }

  @Action
  async [AuthStoreActions.LOGIN](credentials) {
    try {
      const res = await ApiService.M2MClient.post("auth/login", credentials);

      if (res.data.data.phone)
        this.context.commit(AuthStoreMutations.SET_PHONE, res.data.data.phone);
      return true;
    } catch (_) {
      return false;
    }
  }

  @Action
  async [AuthStoreActions.INIT_RECAPTCHA]() {
    try {
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          callback: function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            console.log(response);
          },
          "expired-callback": function () {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        }
      );
      return true;
    } catch (_) {
      return false;
    }
  }

  @Action
  async [AuthStoreActions.SIGN_IN_WITH_PHONE](payload) {
    try {
      if (payload.payloadverifer === null || payload.phone === null) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      firebase
        .auth()
        .signInWithPhoneNumber(payload.phone, payload.verifier)
        .then(function (result) {
          self.context.commit(AuthStoreMutations.SET_CONFIRM_RESULT, result);
          if (typeof payload.callback === "function") {
            payload.callback();
          }
        })
        .catch(function (err) {
          console.log(err);
          alert("Error ! SMS not sent");
          if (typeof payload.fallback === "function") {
            alert("SMS sent");
            payload.fallback(err);
          }
        });
      return true;
    } catch (_) {
      console.log(_);
      return false;
    }
  }

  @Action
  async [AuthStoreActions.VERIFY_CODE](code) {
    try {
      if (this.confirmResult === null) {
        return false;
      }

      return await this.confirmResult!.confirm(code);
    } catch (error) {
      return error;
    }
  }

  @Action
  async [AuthStoreActions.TWO_FACTORY_VERIFY](token: string) {
    try {
      const res = await ApiService.M2MClient.post("user/auth/login", {
        firebase_token: token,
      });
      return res;
    } catch (error) {
      return false;
    }
  }

  @Action
  async [AuthStoreActions.LOGIN_WITH_TOKEN](token: TokenModel) {
    try {
      if (token.available()) {
        return await this.context.dispatch(
          AuthStoreActions.TWO_FACTORY_VERIFY,
          token.token
        );
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}

export default AuthModule;
