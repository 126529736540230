export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile/ProfilePage.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
    redirect: "/general",
    children: [
      {
        name: "General",
        path: "general",
        component: () => import("@/views/profile/tabs/GeneralTab.vue"),
      },
      {
        name: "Aim",
        path: "aim",
        component: () => import("@/views/profile/tabs/AimTab.vue"),
      },
      {
        name: "Additional",
        path: "additional",
        component: () =>
          import("@/views/profile/tabs/AdditionalConditions.vue"),
      },
      {
        name: "Permissions",
        path: "permissions",
        component: () => import("@/views/profile/tabs/PermissionsTab.vue"),
      },
      {
        name: "Language",
        path: "language",
        component: () => import("@/views/profile/tabs/LanguageTab.vue"),
      },
    ],
  },
];
