export enum UnitEnums {
  PIECE,
  KILO,
  LITER,
  WATER_GLASS,
  GRAMS,
  TEA_GLASS,
  TABLESPOON,
  DESSERT_SPOON,
  TEA_SPOON,
  STAMP,
  CUP_LITTLE,
  CUP,
  BUNDLE,
  BRANCH,
  SLICE,
  LADLE,
  FINGER_UP,
  LEAF,
}

export function getUnitLabel(unit: UnitEnums) {
  switch (unit) {
    case UnitEnums.PIECE:
      return "global.units.piece";
    case UnitEnums.KILO:
      return "global.units.kilo";
    case UnitEnums.LITER:
      return "global.units.liter";
    case UnitEnums.WATER_GLASS:
      return "global.units.glass";
    case UnitEnums.GRAMS:
      return "global.units.grams";
    case UnitEnums.TEA_GLASS:
      return "global.units.teaGlass";
    case UnitEnums.TABLESPOON:
      return "global.units.tableSpoon";
    case UnitEnums.DESSERT_SPOON:
      return "global.units.dessertSpoon";
    case UnitEnums.TEA_SPOON:
      return "global.units.teaSpoon";
    case UnitEnums.STAMP:
      return "global.units.stamp";
    case UnitEnums.CUP_LITTLE:
      return "global.units.cupLittle";
    case UnitEnums.CUP:
      return "global.units.cup";
    case UnitEnums.BUNDLE:
      return "global.units.bundle";
    case UnitEnums.BRANCH:
      return "global.units.branch";
    case UnitEnums.SLICE:
      return "global.units.slice";
    case UnitEnums.LADLE:
      return "global.units.ladle";
    case UnitEnums.FINGER_UP:
      return "global.units.fingerUp";
    case UnitEnums.LEAF:
      return "global.units.leaf";
  }
}
