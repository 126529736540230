export default class UserModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  registrationCompleted: boolean;
  avatar: string;
  firebase_uid: string;
  userAccess: number;

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    registrationCompleted: boolean,
    avatar: string,
    firebase_uid: string,
    userAccess: number
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.registrationCompleted = registrationCompleted;
    this.avatar = avatar;
    this.firebase_uid = firebase_uid;
    this.userAccess = userAccess;
  }
}
