import { createI18n } from "vue-i18n";

import tr from "@/assets/localization/tr.json";
import en from "@/assets/localization/en.json";
const messages = {
  tr,
  en,
  // en: {
  //   global: {
  //     cancelBtn: "Discard",
  //     confirmBtn: "Save Changes",
  //   },
  //   auth: {
  //     login: {
  //       title: "Nice to see you again",
  //       subtitle: "Please login to your account",
  //     },
  //   },
  //   views: {
  //     home: {
  //       infoBar: {
  //         title: "Share your photos with us",
  //         subtitle:
  //           "Please share your weekly photo with us. This way we can advance a correct process",
  //       },
  //       meetingsTable: {
  //         title: "Meetings",
  //         subtitle: "{day} {date}",
  //         description: "{meetingName}",
  //         viewBtn: "View",
  //       },
  //       nextMeal: {
  //         title: "Next Meal",
  //         subtitle: "Photograps are expected {numberOfExpected} people",
  //       },
  //     },
  //     measurement: {
  //       measurementNav: {
  //         title: "Measurement",
  //         subtitle: "{day} {date}",
  //       },
  //       measurementDetails: {
  //         userPhoto: "Photo",
  //         weightPhoto: "Weight Photo",
  //         printBtn: "Print",
  //       },
  //     },
  //     profile: {
  //       profileNav: {
  //         general: "General",
  //         aim: "Aim",
  //         additionalConditions: "Additional Conditions",
  //         notificationPreferences: "Notification Preferences",
  //         language: "Language",
  //       },
  //       generalTab: {
  //         tabTitle: "General",
  //         userPhoto: "Photo",
  //         fullName: "Full Name",
  //         phone: "Phone",
  //         email: "E-Mail",
  //         birthDate: "Date of Birth",
  //         country: "Country",
  //         city: "City",
  //         job: "Job",
  //         gender: "Gender",
  //         currentWeight: "Current Weight",
  //         targetWeight: "Target Weight",
  //       },
  //       aimTab: {
  //         title: "Aim",
  //         cancelBtn: "Discard",
  //         confirmBtn: "Save Changes",
  //         losingWeight: "Losing Weight",
  //         gettingFat: "Getting Fat",
  //         healthyEating: "Healthy Eating",
  //         sportsNutrition: "Sport Nutrition",
  //         healthProblem: "Health Problem",
  //         other: "Other",
  //       },
  //       additionalConditions: {
  //         title: "Additional Conditions",
  //         disease: "Disease",
  //         allergy: "Allergy",
  //         bloodAnalysis: "Blood Analysis",
  //         eatingHabit: "Eating Habits",
  //         physicalActivity: "Physical Activity Status",
  //         dailyCaffeine: "Caffeine Consumption",
  //         dailyWater: "Water Consumption",
  //         sleepPatterns: "Sleep Patterns",
  //         surgery: "Have you had surgery ?",
  //         drugs: "Are you using drugs ?",
  //         pregnant: "Are you pregnant ?",
  //         breastfeeding: "Are you breastfeeding ?",
  //         vegan: "Are you vegan?",
  //         vegaterian: "Are you vegaterian ?",
  //         explanation: "Explanation",
  //       },
  //       notificationPreferences: {
  //         title: "Permissions",
  //       },
  //       language: {
  //         title: "Language",
  //       },
  //     },
  //     chat: {
  //       placeholder: "Type a message",
  //       sendBtn: "Send",
  //       sendedInfo: "Sended {hour} ago",
  //     },
  //   },
  //   components: {
  //     downloadAppBox: {
  //       title: "Download our mobile app for a better experience.",
  //       description:
  //         "You can access all the features through our mobile application.",
  //       downloadBtn: "Download App",
  //     },
  //     header: {
  //       title: "Hello {userName} 👋",
  //       subtitle: "What’s your mood today?",
  //     },
  //     asideSecondary: {
  //       homeTab: {
  //         title: "Home",
  //       },
  //       dietsTab: {
  //         title: "Diets",
  //         current: "Current",
  //         past: "Past",
  //       },
  //       measurementTab: {
  //         title: "Measurements",
  //       },
  //       MeetingsTab: {
  //         title: "Meets",
  //       },
  //       profileTab: {
  //         title: "Profile",
  //       },
  //       chatTab: {
  //         title: "Messages",
  //       },
  //     },
  //     modals: {
  //       recipeAppModal: {
  //         title: "Recipe",
  //       },
  //       downloadAppModal: {
  //         title: "Select Platform",
  //       },
  //     },
  //   },
  // },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
