export default [
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    // children: [
    //   {
    //     // the 404 route, when none of the above matches
    //     path: "/404",
    //     name: "404",
    //     component: () => import("@/views/crafted/authentication/Error404.vue"),
    //     meta: {
    //       pageTitle: "Error 404",
    //     },
    //   },
    //   {
    //     path: "/500",
    //     name: "500",
    //     component: () => import("@/views/crafted/authentication/Error500.vue"),
    //     meta: {
    //       pageTitle: "Error 500",
    //     },
    //   },
    // ],
  },
];
