export default [
  {
    path: "/meets",
    name: "meets",
    component: () => import("@/views/meets/MeetsPage.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
];
