<template>
  <router-view />
  <ModalContainer />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import ModalContainer from "@/views/components/modals/ModalContainer.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "app",
  components: {
    ModalContainer,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      i18n.locale.value = window.localStorage.getItem("Language") || "en";
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";

.multiselect {
  //.multiselect-clear {
  //  display: none !important;
  //}

  .multiselect-options {
    border-radius: 5px;

    .multiselect-option {
      transition: 0.3s ease;

      &.is-selected {
        background: #80b918;
      }
    }
  }
}
</style>
