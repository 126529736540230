import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import UserModule from "@/store/modules/UserModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import Modals from "@/store/modules/Modals";
import Meals from "@/store/modules/Meals";
import Login from "@/store/modules/login";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    Modals,
    Meals,
    Login,
    UserModule,
  },
});

export default store;
