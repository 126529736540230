import { TokenTypeEnum } from "@/store/enums/TokenTypeEnum";
import TokenModel from "@/store/models/TokenModel";

/**
 * @param tokenType: TokenTypeEnum
 * @description get token form localStorage
 */
export const getToken = (tokenType: TokenTypeEnum): TokenModel => {
  const expireAt = window.localStorage.getItem("expire_at");
  return new TokenModel(
    window.localStorage.getItem(tokenType),
    expireAt !== null ? new Date(expireAt) : null
  );
};

/**
 * @description save token into localStorage
 * @param tokenType: TokenTypeEnum
 * @param token: TokenModel
 */
export const saveToken = (
  tokenType: TokenTypeEnum,
  token: TokenModel
): void => {
  if (token.token !== null) window.localStorage.setItem(tokenType, token.token);
  if (token.expireAt !== null)
    window.localStorage.setItem("expire_at", token.expireAt.toString());
};

/**
 * @param tokenType: TokenTypeEnum
 * @description remove token form localStorage
 */
export const destroyToken = (tokenType: TokenTypeEnum): void => {
  window.localStorage.removeItem(tokenType);
  window.localStorage.removeItem("expire_at");
};

export default { getToken, saveToken, destroyToken };
