import { AuthGuards } from "@/router/middleware/enums/AuthGuards";
import TokenModel from "@/store/models/TokenModel";
import JwtService from "@/core/services/JwtService";
import { TokenTypeEnum } from "@/store/enums/TokenTypeEnum";
import store from "@/store";
import { UserStoreActions } from "@/store/enums/UserStoreEnum";
import { MiddlewareInterface } from "@/router/middleware/MiddlewareInterface";
import router from "@/router";

export default class AuthMiddleware implements MiddlewareInterface {
  public guard: AuthGuards;
  private tokenAvailable = false;

  constructor(guard: AuthGuards) {
    this.guard = guard;
  }

  public async protect(to) {
    const token: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);
    this.tokenAvailable = token.available();
    let can = false;
    switch (this.guard) {
      case AuthGuards.GUEST:
        can = await this.guest();
        if (!can) {
          await router.push({ name: "home" });
        }
        break;
      case AuthGuards.AUTH:
        can = await this.auth();

        if (!can) {
          await router.push({ name: "login" });
        } else {
          if (to.name === "register-home" || to.name === "register") {
            if (store.getters.getUser.registrationCompleted) {
              await router.push({ name: "home" });
            }
          } else {
            if (!store.getters.getUser.registrationCompleted) {
              await router.push({ name: "register-home" });
            }
          }
        }
        break;
      case AuthGuards.SYSTEM:
        // can = this.system();
        break;
    }
  }

  private async guest() {
    return !(await this.auth());
  }

  private async auth() {
    if (store.getters.getUser !== null) {
      return true;
    }
    if (this.tokenAvailable) {
      try {
        await store.dispatch(UserStoreActions.SHOW);
        return store.getters.getUser !== null;
      } catch (e) {
        return false;
      }
    }
    return false;
  }
}
