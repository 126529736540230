export default {
  src(path: string): string {
    const baseUrl ="/";
    const firstChar = path.charAt(0);
    if (firstChar === "/") {
      path = path.slice(1);
    }
    return baseUrl.concat(path);
  },
};
