import firebase from "firebase/app";

firebase.initializeApp({
  apiKey: "AIzaSyAgjbmbtu8J_ZIgXsvcPf7OhPU8yhkakxY",
  authDomain: "rome-9f0ee.firebaseapp.com",
  projectId: "rome-9f0ee",
  storageBucket: "rome-9f0ee.appspot.com",
  messagingSenderId: "396832324480",
  appId: "1:396832324480:web:61620a53577c6d9f4b9f65",
});

// apiKey: "AIzaSyCOoSMZY_gv2VuMaWPHZpxRHgTBp_8qCNw",
//     authDomain: "ro-me-77a5e.firebaseapp.com",
//     projectId: "ro-me-77a5e",
//     storageBucket: "ro-me-77a5e.appspot.com",
//     messagingSenderId: "961064689031",
//     appId: "1:961064689031:web:553ab94b53b9f71a7d4722",
