<template>
  <card-loader v-if="loading"></card-loader>
  <ElModal v-else :title="title" modal-size="lg">
    <template v-slot:title
      ><span style="color: #80b918"
        >{{ $t("components.modals.recipeAppModal.title") }}:</span
      ></template
    >
    <template v-slot:body>
      <CardLoader v-if="false" />
      <div class="recipe-list">
        <div v-if="nutrients?.length > 0" class="recipe" v-for="(recipe, index) in nutrients" :key="index">
          <div class="material-list">{{ recipe.material }}</div>
          <div class="amount-list">{{ recipe.amount }}</div>
        </div>
        <p v-else>Tarif bulunamadı.</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="recipe-detail">
        <p>{{ description }}</p>
      </div>
    </template>
  </ElModal>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import ElModal from "@/views/components/Modal.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { getUnitLabel } from "@/store/enums/UnitEnum";
import CardLoader from "@/components/loader/CardLoader.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "RecipeModal",
  components: {
    CardLoader,
    ElModal,
  },
  setup() {
    const loading = ref(true);
    const store = useStore();
    const id = store.getters.getModalData;
    const nutrients = ref();
    const title = ref();
    const description = ref();
    const { t } = useI18n();
    onMounted(async () => {
      loading.value = true;
      console.log(id);
      try {
        const res = await ApiService.U2MClient.get(
          `user/diet-detox/recipes/${id}`
        );
        nutrients.value = res.data.data.nutrients.map((item) => {
          return {
            material: item.name,
            amount: `${item.quantity} ${t(`${getUnitLabel(item.unit)}`)}`,
          };
        });
        title.value = res.data.data.name;
        description.value = res.data.data.description;
      } catch (error) {
        console.log(error.message);
      }
      console.log(nutrients.value);
      loading.value = false;
    });
    const dummyData = [
      {
        material: "Şeker",
        amount: "100 gr",
      },
      {
        material: "Un",
        amount: "100 gr",
      },
      {
        material: "Kakao",
        amount: "100 gr",
      },
    ];
    return {
      dummyData,
      loading,
      nutrients,
      description,
      title,
    };
  },
});
</script>

<style lang="scss">
.recipe-list {
  .recipe {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    padding: 1.5rem 0;
    border-bottom: 1px solid #eff2f5;
    .material-list {
      color: #8e9aaa;
    }
    &:last-child {
      border: none;
    }
  }
}
.recipe-detail {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 0 0 10px 30px;
}
</style>
