export default [
  {
    path: "/messages",
    name: "messages",
    component: () => import("@/views/chat/Chat.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
];
