import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import VueSplide from "@splidejs/vue-splide";
import Multiselect from "@vueform/multiselect";
import CardLoader from "@/components/loader/CardLoader.vue";
import "@/core/firebase";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
//@ts-ignore

import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";
import ProdImage from "@/core/helpers/ProdImage";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueSplide);
app.component("MultiSelect", Multiselect);
app.component("CardLoader", CardLoader);
app.component("DatePicker", Datepicker);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

app.config.globalProperties.$prodImage = ProdImage;
