export default [
  {
    path: "/package",
    name: "packages",
    component: () => import("@/views/packages/PackagesMain.vue"),
  },
  {
    path: "/package/:id/select-date",
    name: "select-date",
    component: () => import("@/views/packages/PackageDate.vue"),
  },
];
