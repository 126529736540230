import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import UserModel from "@/store/models/UserModel";
import {
  UserStoreActions,
  UserStoreMutations,
} from "@/store/enums/UserStoreEnum";
import JwtService from "@/core/services/JwtService";
import { TokenTypeEnum } from "@/store/enums/TokenTypeEnum";
import TokenModel from "@/store/models/TokenModel";
import ApiService from "@/core/services/ApiService";

@Module
class UserModule extends VuexModule {
  user: UserModel | null = null;

  get getUser(): UserModel | null {
    return this.user;
  }

  @Mutation
  [UserStoreMutations.SET_USER_TO_STATE](userObject) {
    this.user = new UserModel(
      userObject.first_name,
      userObject.last_name,
      userObject.email,
      userObject.phone,
      userObject.registration_completed,
      userObject.media.avatar,
      userObject.firebase_uid,
      userObject.user_access_status
    );
  }
  @Mutation
  [UserStoreMutations.HANDLE_LOGOUT]() {
    this.user = null;
  }
  @Action
  async [UserStoreActions.SET_U2M_TO_STORAGE](token: TokenModel) {
    JwtService.saveToken(TokenTypeEnum.U2M, token);
  }

  @Action
  async [UserStoreActions.SHOW]() {
    const result = await ApiService.U2MClient.get("user/user/show");
    console.log(result.data.data);
    this.context.commit(UserStoreMutations.SET_USER_TO_STATE, result.data.data);
  }
}

export default UserModule;
