export default [
  {
    path: "/register-home",
    name: "register-home",
    component: () => import("@/views/auth/register/RegisterCover.vue"),
    meta: {
      pageTitle: "Register",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/register/Register.vue"),
    meta: {
      pageTitle: "Register",
    },
  },
];
