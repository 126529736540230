<template>
  <div>
    <component :is="store.getters.getActiveModal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RecipeModal from "@/views/components/modals/contents/RecipeModal.vue";
import DownloadAppModal from "@/views/components/modals/contents/DownloadAppModal.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "ModalContainer",
  components: {
    RecipeModal,
    DownloadAppModal,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
});
</script>
<style lang="scss"></style>
