import { Modals } from "@/store/enums/StoreEnums";
import { ModalPayloadInterface } from "@/store/interfaces/modal/ModalPayloadInterface";

const modals = {
  state: () => ({
    activeModal: null,
    data: null,
    callback: null,
    fallback: null,
    basketItems: [],
  }),
  mutations: {
    closeModal(state, modal) {
      state.activeModal = null;
      state.data = null;
      state.callback = null;
      state.fallback = null;
    },
    setModal(state, payload: ModalPayloadInterface) {
      state.activeModal = payload.modal;
      state.data = payload.data;
      state.callback = payload.callback;
      state.fallback = payload.fallback;
    },
    setBasketItems(state, payload: any) {
      state.basketItems = payload.basketItems;
    },
  },
  getters: {
    [Modals.RECIPE_MODAL](state) {
      return state.recipe;
    },
    [Modals.DWONLOAD_APP_MODAL](state) {
      return state.downloadApp;
    },
    getActiveModal(state) {
      return state.activeModal;
    },
    getModalData(state) {
      return state.data;
    },
    getBasketItems(state) {
      return state.basketItems;
    },
  },
};

export default modals;
