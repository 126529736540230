export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/HomePage.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumbs: ["Home"],
    },
  },
];
