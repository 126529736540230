<template>
  <div
    class="w-100 d-flex justify-content-center align-items-center m-0 p-0"
    style="height: 200px"
  >
    <div class="overlay-layer card-rounded bg-dark bg-opacity-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-loader",
});
</script>

<style>
.spinner-border {
  border-color: #80b918 #80b918 transparent;
}
</style>
