export default [
  {
    component: () => import("@/layouts/AuthLayout.vue"),
    name: "login",
    path: "/login",
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/login/LoginView.vue"),
        meta: {
          pageTitle: "Giriş Yap",
        },
      },
      {
        path: "otp",
        component: () => import("@/views/auth/login/OTPSection.vue"),
        name: "otp",
        meta: {
          pageTitle: "Kod",
        },
      },
    ],
  },
];
