export default [
  {
    path: "/diet/current",
    name: "diet-current",
    // TODO component will be changed
    component: () => import("@/views/diets/current/CurrentTab.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  {
    path: "/diet/current/:id",
    name: "diet-current-detail",
    // TODO component will be changed
    component: () => import("@/views/diets/current/components/LuchTable.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  /* Past */
  {
    path: "/diet/past",
    name: "diet-past",
    // TODO component will be changed
    component: () => import("@/views/diets/past/PastTab.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  {
    path: "/diet/past/:id",
    name: "diet-past-detail",
    // TODO component will be changed
    component: () => import("@/views/diets/past/components/OnlineDiet.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  {
    path: "/diet/past/:id/:meals",
    name: "diet-past-detail-meals",
    // TODO component will be changed
    component: () =>
      import("@/views/diets/past/components/TimeLineWithNav.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  {
    path: "/diet/past/:id/:meals/:meal",
    name: "diet-past-detail-meals-meal",
    // TODO component will be changed
    component: () => import("@/views/diets/past/components/LucnhTable.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  {
    path: "/diet/past/:id/before-afters",
    name: "diet-past-meal-detail",
    // TODO component will be changed
    component: () =>
      import("@/views/diets/past/components/BeforeAfterTable.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumbs: ["Dashboards"],
    },
  },
  /* Past */
];
