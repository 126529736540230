import { App } from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { TokenTypeEnum } from "@/store/enums/TokenTypeEnum";
import TokenModel from "@/store/models/TokenModel";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public static GuestClient;
  public static M2MClient;
  public static U2MClient;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    const defaultConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false,
    };
    ApiService.GuestClient = ApiService.vueInstance.axios.create(defaultConfig);
    ApiService.M2MClient = ApiService.vueInstance.axios.create(defaultConfig);

    ApiService.U2MClient = ApiService.vueInstance.axios.create(defaultConfig);

    ApiService.M2MClient.interceptors.request.use(
      async function (config) {
        const m2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.M2M);
        if (!m2mToken.available()) {
          const data = await ApiService.GuestClient.post("oauth/token", {
            grant_type: "client_credentials",
            client_id: "97336ac7-7b98-4834-b38b-64251c735e80",
            client_secret: "seimnWqy80FCcqIFghd0AJzNPD6ja5DM0zxXrTLL",
            scope: "user",
          });
          m2mToken.token = data.data.access_token;
          JwtService.saveToken(TokenTypeEnum.M2M, m2mToken);
        }
        config.headers.common["Authorization"] = `Bearer ${m2mToken.token}`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    ApiService.U2MClient.interceptors.request.use(
      async function (config) {
        const u2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);

        if (u2mToken.available()) {
          config.headers.common["Authorization"] = `Bearer ${u2mToken.token}`;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
