import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthMiddleware from "@/router/middleware/AuthMiddleware";
import { AuthGuards } from "@/router/middleware/enums/AuthGuards";

import SystemRoutes from "@/router/routes/system";
import AuthRoutes from "@/router/routes/auth";
import DietRoutes from "@/router/routes/diets";
import HomeRoutes from "@/router/routes/home";
import MeasurementRoutes from "@/router/routes/measurements";
import MeetRoutes from "@/router/routes/meets";
import MessageRoutes from "@/router/routes/messages";
import ProfileRoutes from "@/router/routes/profile";
import RegisterRoutes from "@/router/routes/register";
import PackagesRoutes from "@/router/routes/packages";
import BasketRoutes from "@/router/routes/basket";
import BankRoutes from "@/router/routes/bank";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      guard: AuthGuards.AUTH,
    },
    children: [
      ...DietRoutes,
      ...HomeRoutes,
      ...MeasurementRoutes,
      ...MeetRoutes,
      ...MessageRoutes,
      ...ProfileRoutes,
      ...PackagesRoutes,
      ...BasketRoutes,
      ...BankRoutes,
    ],
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      guard: AuthGuards.GUEST,
    },
    children: [...AuthRoutes],
  },
  {
    path: "/iyzico-redirect",
    name: "asfsdf",
    component: () => import("@/views/payment/IyzıcoContainer.vue"),
  },
  {
    path: "/meet-room/:id",
    name: "meet-room",
    component: () => import("@/views/jitsimeet/jitsiComp.vue"),
  },

  {
    path: "/register-home",
    name: "register-home",
    redirect: "/register-home",
    meta: {
      guard: AuthGuards.AUTH,
    },
    children: [...RegisterRoutes],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [...SystemRoutes],
  },
  {
    path: "/login-with-mobile-token",
    component: () => import("@/views/LoginWithMobileToken.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  document.title = `${to.meta.pageTitle} | Dyt. Mehtap Yakut`;
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  if (to.meta.guard !== null) {
    const middleware = new AuthMiddleware(to.meta.guard as AuthGuards);
    console.log(to.path);
    await middleware.protect(to);
  }
});

export default router;
