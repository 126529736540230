<template>
  <ElModal modal-size="md">
    <template v-slot:title>{{
      $t("components.modals.downloadAppModal.title")
    }}</template>
    <template v-slot:body>
      <CardLoader v-if="false" />
      <div class="platforms-wrapper">
        <a :href="goTo('appStore')" target="_blank" class="platform">
          <inline-svg :src="$prodImage.src(`/media/app-store.svg`)" />
        </a>
        <a :href="goTo('playStore')" target="_blank" class="platform">
          <inline-svg :src="$prodImage.src(`/media/google-play.svg`)" />
        </a>
      </div>
    </template>
  </ElModal>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import ElModal from "@/views/components/Modal.vue";
export default defineComponent({
  name: "DownloadAppModal",
  components: {
    ElModal,
  },
  setup() {
    const platforms = ref([
      {
        name: "App Store",
        link: "#",
        svg: "/media/icons/duotune/custom/app-store.svg",
      },
      {
        name: "Play Store",
        link: "#",
        svg: "/media/icons/duotune/custom/play-store.svg",
      },
    ]);

    const goTo = computed(() => (platform) => {
      const platforms = {
        playStore:
          "https://play.google.com/store/apps/details?id=com.kumsalajans.rome",
        appStore:
          "https://apps.apple.com/tr/app/diyetisyen-mehtap-yakut/id1661767565",
      };
      return platforms[platform];
    });
    return {
      platforms,
      goTo,
    };
  },
});
</script>

<style lang="scss">
.platforms-wrapper {
  display: flex;
  justify-content: space-between;
  .platform {
    border: 1px solid #eff0f2;
    border-radius: 100px;
    padding: 20px 36px;
    @media screen and (max-width: 600px) {
      padding: 10px 23px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
